import React from 'react';

const Home: React.FunctionComponent = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Welcome to Cat Lover</h1>
      <p className="text-lg mb-4">
        The Cat Lover app provides you with 10 random photos of cats every day and allows you to look up information about different cat breeds.
      </p>
      <a
        href="https://apps.apple.com/app/cat-lover/id6458347447"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      >
        Download on the App Store
      </a>
    </div>
  );
};

export default Home;