import React, { useState, useRef, useEffect } from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";

const HamburgerButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuTapped = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (location.pathname === e.currentTarget.pathname) {
      e.preventDefault(); 
      setIsOpen(false); 
    } else {
      setIsOpen(false); 
      window.scrollTo(0, 0); 
    }
  };


  const handleOutsideClick = (event: TouchEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("touchstart", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div>
    <button
      className="sm:block md:hidden fixed bottom-4 right-4 p-4 bg-gray-600 text-white rounded-md"
      onClick={toggleMenu}
    >
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16m-7 6h7"
        ></path>
      </svg>
    </button>

    {isOpen && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div ref={menuRef} className="bg-white p-4 rounded-md">
            <ul className="text-center font-normal">
              <li className="my-5">
                <Link to="/" className={
                    location.pathname === "/" ? "text-blue-600 font-bold" : ""
                  } 
                  onClick={handleMenuTapped}>Home</Link>
              </li>
              <li className="mb-5">
                <Link to="/privacy-policy" className={
                    location.pathname === "/privacy-policy" ? "text-blue-600 font-bold" : ""
                  }
                  onClick={handleMenuTapped}>Privacy Policy</Link>
              </li>
              <li className="mb-5">
                <Link to="/terms-and-conditions" className={
                    location.pathname === "/terms-and-conditions" ? "text-blue-600 font-bold" : ""
                  }
                  onClick={handleMenuTapped}>Terms and Conditions</Link>
              </li>
              <li className="mb-5">
                <Link to="/contact" className={
                    location.pathname === "/contact" ? "text-blue-600 font-bold" : ""
                  } 
                  onClick={handleMenuTapped}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
    )}
    </div>
  );
};

export default HamburgerButton;