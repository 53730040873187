import Contact from './contact';
import Home from './home';

import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import Base from './base';
import Privacy from './privacyPolicy';
import TermAndConditions from './termAndConditions';

function App() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Base />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms-and-conditions" element={<TermAndConditions />} />
        </Route>
      </Routes>
    </div>


  );
}

export default App;
