import React from 'react';

const Contact: React.FC = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
            <p className="text-lg mb-4">
                If you have any questions or inquiries, feel free to reach out to us at:
            </p>
            <a
                href={`mailto:info@catlover.click`}
                className="text-xl text-blue-500 mb-8 hover:text-blue-800"
            >info@catlover.click</a>
        </div>
    );
};

export default Contact;