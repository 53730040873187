import Footer from './footer';
import Header from './header';
import React from "react";
import {
  Outlet,
} from "react-router-dom";
import HamburgerButton from './HamburgerButton';

function Base() {

  return (
    <div>
      <Header />
      <HamburgerButton />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Base;