import React from 'react';
import {
  Link
} from "react-router-dom";

const Header: React.FunctionComponent = () => {
  return (
    <header className="bg-blue-500 p-4">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center space-x-2">
          <img src="/cat-lover-low-resolution-logo-white-on-transparent-background.png" alt="Logo" className="hidden md:flex w-18 h-12" />
          <h1 className="text-white text-lg font-semibold">Cat Lover</h1>
        </div>
        <nav className="hidden md:flex mt-4 sm:mt-0">
          <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <li>
              <Link to="/" className="text-white hover:text-blue-200">Home</Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="text-white hover:text-blue-200">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions" className="text-white hover:text-blue-200">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/contact" className="text-white hover:text-blue-200">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;